<template>
  <div>
    <h4>
    <span>Ny Lønnsimport</span>
    </h4>
    <a-spin :spinning="loading">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <a-form-item label="Type" required>
              <a-select :options="importTypes" style="width: 100%" @change="selectImportType"/>
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item label="Prosjekt" required>
              <a-select :options="projects" style="width: 100%" v-model="selectedProject"/>
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item label="Periode" required>
              <a-month-picker v-model="period"/>
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item label="Tittel" required>
              <a-input style="width: 100%" v-model="title"/>
            </a-form-item>
          </div>
          <div class="col-md-12">
            <a-form-item label="Data" required>
              <a-textarea placeholder="Notater" :rows="4" v-model="input" @change="convertInput" />
            </a-form-item>
          </div>
          <a-button style="width: 150px;" type="primary"  class="mt-3" @click="importData" :disabled="Boolean(!data.length) ||  Boolean(validationErrors.length)">Importer</a-button>
        </div>
      </div>
    </div>
    <a-table :dataSource="forTable(data)" :columns="columns"/>
    </a-spin>
    <div v-if="validationErrors.length > 0" class="mt-3">
      <a-card title="Validation Errors" bordered>
        <ul>
          <li v-for="(error, index) in validationErrors" :key="index">
            Row {{ error.row + 1 }}: {{ error.field }} - {{ error.message }}
          </li>
        </ul>
      </a-card>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'
import moment from 'moment'

const importTypes = [
  {
    value: 'sale',
    label: 'Salg',
    headers: [
      {
        name: 'employeeID',
        required: true,
        type: 'string',
      },
      {
        name: 'department',
        required: true,
        type: 'string',
      },
      {
        name: 'amount',
        required: true,
        type: 'number',
      },
      {
        name: 'createdDate',
        required: true,
        type: 'date',
      },
      {
        name: 'comment',
        required: true,
        type: 'string',
      },
      {
        name: 'orderID',
        required: true,
        type: 'string',
      },
      {
        name: 'orderLineID',
        required: false,
        type: 'string',
      },
      {
        name: 'campaign',
        required: false,
        type: 'string',
      },
      {
        name: 'revenue',
        required: true,
        type: 'number',
      },
    ],
    attributes: {
      type: 'sale',
      company: 'Skycall',
    },
  },
]

function chunkArray(array, chunkSize) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export default {
  data() {
    return {
      input: '',
      title: '',
      data: [],
      columns: [],
      projects: [],
      importTypes,
      importType: {},
      selectedProject: '',
      loading: false,
      period: moment().format('YYYYMM'),
      validationErrors: [],
      employeeIds: [],
      employeeIdsLoaded: false,
    }
  },
  mounted() {
    firestore.collection('settings').doc('projects').collection('projects')
      .where('company', '==', 'Skycall')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            value: doc.id,
            label: doc.data().name,
          }
          this.projects.push(project)
        })
      })
    firestore.collection('users')
      .where('companies', 'array-contains', 'Skycall')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          this.employeeIds.push(doc.data().employeeID)
        })
        this.employeeIdsLoaded = true
      })
  },
  methods: {
    moment,
    async importData() {
      this.loading = true
      // add validation
      try {
        const data = this.data
        const transactionIDs = []

        // Create payrollImport document without transactions attribute
        const payrollImportDocRef = await firestore.collection('payrollImports').add({
          date: String(moment().format('YYYY-MM-DDTHH:mm')),
          importBy: this.$store.state.user.employeeID,
          importByName: this.$store.state.user.name,
          project: this.selectedProject,
          period: String(moment(this.period).format('YYYYMM')),
          title: this.title,
        })

        // Split data into chunks
        const chunkSize = 500
        const dataChunks = chunkArray(data, chunkSize)

        // Process each chunk
        for (const chunk of dataChunks) {
          // Initialize batch
          const batch = firestore.batch()

          // Add transactions with payrollImportDocRef to batch
          chunk.forEach((line) => {
            const nLine = {
              ...line,
              ...this.importType.attributes,
              project: this.selectedProject,
              period: this.period,
              status: 'unpaid',
              payrollImportDocRef: payrollImportDocRef.id, // Add payrollImport document reference
            }
            const transactionDocRef = firestore.collection('payrollTransactions').doc()
            transactionIDs.push(transactionDocRef.id)
            batch.set(transactionDocRef, nLine)
          })

          // Commit the batch
          await batch.commit()
        }

        // Update payrollImport document with transactions attribute
        await payrollImportDocRef.update({
          transactions: transactionIDs,
        })

        openNotification('Data importert')
        this.data = []
        this.input = ''
      } catch (err) {
        console.log(err)
        openNotification('Feil ved import, vennligst prøv igjen')
      } finally {
        this.loading = false
      }
    },
    selectImportType(e) {
      const value = e
      console.log(value)
      const obj = this.importTypes.find(o => o.value === value)
      this.importType = obj
      const headerNames = obj.headers.map(item => {
        return item.name
      })
      headerNames.forEach(header => {
        this.columns.push({
          title: header,
          dataIndex: header,
          key: header,
        })
      })
    },
    forTable(data) {
      data.forEach((el, i) => {
        data[i].key = i
      })
      return data
    },
    convertInput() {
      if (this.importType.headers) {
        const { lines, headers } = this.tsvToJson(this.input)
        console.log(lines)
        if (lines.length) {
          if (this.checkHeaders(headers, this.importType.headers)) {
            this.validationErrors = this.validateData(lines, this.importType.headers)
            if (this.validationErrors.length === 0) {
              this.data = lines
              this.$forceUpdate()
            }
          } else {
            openNotification('Data samsvarer ikke med valgt datatype')
          }
        } else {
          openNotification('Feil ved lesing av data')
        }
      }
    },
    tsvToJson (tsvText) {
      // Split all the text into seperate lines on new lines and carriage return feeds
      var allTextLines = tsvText.split(/\r\n|\n/)
      // Split per line on tabs and commas
      var headers = allTextLines[0].split(/\t/)
      var lines = []
      for (var i = 1; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(/\t/)
        if (data.length === headers.length) {
          var row = {}
          for (var j = 0; j < headers.length; j++) {
            row[headers[j]] = data[j]
          }
          lines.push(row)
        }
      }
      return {
        lines,
        headers,
      }
    },
    checkHeaders(headers, requiredHeaders) {
      const headerNames = requiredHeaders.map(item => {
        return item.name
      })
      return this.arraysEqual(headers, headerNames)
    },
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false
      }
      arr1.sort()
      arr2.sort()
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false
        }
      }
      return true
    },
    validateData(data, validators) {
      const errors = []
      const dateFormats = ['MM/DD/YYYY HH:mm', 'YYYY-MM-DD HH:mm', 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY']

      data.forEach((item, rowIndex) => {
        validators.forEach((validator) => {
          const value = item[validator.name]
          let errorMsg = ''

          if (validator.required && (value === null || value === undefined || value === '')) {
            errorMsg = 'This field is required'
          } else if (validator.type === 'string' && typeof value !== 'string') {
            errorMsg = 'Value should be a string'
          } else if (validator.type === 'number') {
            const cleanedValue = this.cleanNumber(value)
            if (isNaN(Number(cleanedValue))) {
              errorMsg = 'Value should be a number'
            } else {
              item[validator.name] = cleanedValue
            }
          } else if (validator.type === 'date') {
            const convertedDate = moment(value, dateFormats, true)
            if (convertedDate.isValid()) {
              item[validator.name] = convertedDate.format('YYYY-MM-DD')
            } else {
              errorMsg = 'Invalid date format'
            }
          }
          if (validator.name === 'employeeID') {
            if (this.employeeIdsLoaded && !this.employeeIds.includes(value)) {
              errorMsg = 'Invalid employee ID'
            }
          }

          // Sanitize and convert number fields
          if (validator.type === 'number' && !errorMsg) {
            const sanitizedNumber = parseFloat(value.replace(',', '.').replace(/[^0-9.]/g, ''))
            if (isNaN(sanitizedNumber)) {
              errorMsg = 'Invalid number'
            } else {
              item[validator.name] = sanitizedNumber
            }
          }

          // Sanitize and convert date fields
          if (validator.type === 'date' && !errorMsg) {
            const convertedDate = moment(value, dateFormats, true)
            if (convertedDate.isValid()) {
              item[validator.name] = convertedDate.format('YYYY-MM-DDTHH:mm')
            } else {
              errorMsg = 'Invalid date format'
            }
          }

          if (errorMsg) {
            errors.push({ row: rowIndex, field: validator.name, message: errorMsg })
          }
        })
      })

      return errors
    },
    cleanNumber(value) {
      if (typeof value === 'string') {
        return value.replace(',', '.')
      }
      return value
    },
  },
}
</script>

<style>

</style>
