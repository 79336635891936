<template>
  <div>
    <h4>
    <span>Lønnsimport</span>
    </h4>
    <!-- Display old imports -->
    <oldImports  v-if="!newImport"/>
    <!-- New Imports -->
    <a-divider />
    <a-button @click="newImport = !newImport">Ny import</a-button>
    <importPayroll v-if="newImport" class="mt-3"/>
  </div>
</template>

<script>
import oldImports from '@/components/custom/payroll/oldImports'
import importPayroll from '@/components/custom/payroll/import'

export default {
  components: {
    importPayroll,
    oldImports,
  },
  data() {
    return {
      newImport: false,
    }
  },
}
</script>
