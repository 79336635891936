<template>
  <div>
    <!-- filters -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <a-form-item label="Type" required>
              <a-select :options="importTypes" style="width: 100%" @change="getData()" v-model="filter.type" />
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item label="Prosjekt" required>
              <a-select :options="projects" style="width: 100%"  @change="getData()" v-model="filter.project"/>
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item label="Periode">
              <a-month-picker v-model="filter.period"/>
            </a-form-item>
          </div>
          <!-- <div class="col-md-12">
            <a-form-item label="Data" required>
              <a-textarea placeholder="Notater" :rows="4" v-model="input" @change="convertInput" />
            </a-form-item>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Display imports -->
    <a-spin :spinning="loading">
      <a-table bordered :data-source="dataSource" :columns="columns">
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="dataSource.length && record.status!= 'blocked'"
            title="Sure to delete?"
            @confirm="() => onDelete(record.id)"
          >
            <a href="javascript:;">Slett</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'
import moment from 'moment'

const importTypes = [
  {
    value: 'sale',
    label: 'Salg',
    headers: [
      {
        name: 'employeeID',
        required: true,
        type: 'string',
      },
      {
        name: 'department',
        required: true,
        type: 'string',
      },
      {
        name: 'amount',
        required: true,
        type: 'number',
      },
      {
        name: 'createdDate',
        required: true,
        type: 'date',
      },
      {
        name: 'comment',
        required: true,
        type: 'string',
      },
      {
        name: 'orderID',
        required: true,
        type: 'string',
      },
      {
        name: 'orderLineID',
        required: false,
        type: 'string',
      },
      {
        name: 'campaign',
        required: false,
        type: 'string',
      },
      {
        name: 'revenue',
        required: true,
        type: 'number',
      },
    ],
    attributes: {
      type: 'sale',
      company: 'Skycall',
    },
  },
]

function chunkArray(array, chunkSize) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export default {
  data() {
    return {
      newImport: false,
      input: '',
      importTypes,
      dataSource: [],
      projects: [],
      loading: false,
      filter: {
        type: '',
        project: '',
        period: moment().format('YYYY-MM'),
      },
      columns: [
        {
          title: 'Dato',
          dataIndex: 'date',
        },
        {
          title: 'Tittel',
          dataIndex: 'title',
        },
        {
          title: 'Periode',
          dataIndex: 'period',
        },
        {
          title: 'Prosjekt',
          dataIndex: 'project ',
        },
        {
          title: 'Importert Av',
          dataIndex: 'importByName',
        },
        {
          title: 'Slett',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  mounted() {
    firestore.collection('settings').doc('projects').collection('projects')
      .where('company', '==', 'Skycall')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            value: doc.id,
            label: doc.data().name,
          }
          this.projects.push(project)
        })
      })
    this.getData()
  },
  methods: {
    moment,
    getData() {
      const { filter } = this
      console.log(filter)
      firestore.collection('payrollImports')
        // .where('year', '==', filter.year)
        // .where('month', '==', filter.month)
        // .where('type', '==', filter.type || '')
        // .where('project', '==', filter.project || '')
        .orderBy('date', 'asc')
        .get().then(snapshot => {
          this.dataSource = []
          this.count = 0
          snapshot.forEach(doc => {
            const data = doc.data()
            data.id = doc.id
            this.dataSource.push(data)
            this.count += 1
          })
          console.log(this.dataSource)
        })
    },
    async onDelete(importId) {
      this.loading = true
      const dataSource = [...this.dataSource]
      const target = dataSource.find((obj) => obj.id === importId)

      if (target.status !== 'blocked') {
        // Chunk the transaction IDs
        const chunkSize = 500 // Firestore supports up to 500 writes per batch
        const transactionChunks = chunkArray(target.transactions, chunkSize)

        // Delete transactions connected to this import
        const deleteTransactionsPromises = transactionChunks.map((chunk) => {
          const batch = firestore.batch()
          chunk.forEach((transactionId) => {
            const transactionRef = firestore
              .collection('payrollTransactions')
              .doc(transactionId)
            batch.delete(transactionRef)
          })
          return batch.commit()
        })

        await Promise.all(deleteTransactionsPromises)

        // Delete the import document itself
        await firestore.collection('payrollImports').doc(target.id).delete()

        // Remove the import from the dataSource and force a component update
        this.dataSource = dataSource.filter((obj) => obj.id !== importId)
        this.$forceUpdate()
        openNotification('Import slettet')
      }
      this.loading = false
    },
  },
}
</script>

<style>

</style>
